<template>
    <div class="home">
        <v-card>
            <Breadcrumbs />
            <v-row>
                <v-col cols="12">
                    <div style="padding: 10px">
                        <v-row class="pl-4">
                            <v-col cols="12" style="padding-bottom: 0px">
                                <h3>Change Password</h3>
                            </v-col>
                            <v-col
                                cols="12"
                                sm="12"
                                md="4"
                                lg="3"
                                xl="3"
                            >
                                <v-text-field
                                    label="New Password"
                                    type="password"
                                    v-model="password"
                                    :rules="[rules.min6]"
                                    hide-details="auto"
                                ></v-text-field>
                            </v-col>
                            <v-col
                                cols="12"
                                sm="12"
                                md="4"
                                lg="3"
                                xl="3"
                            >
                                <v-text-field
                                    label="Confirm New Password"
                                    type="password"
                                    v-model="reEnteredPassword"
                                    :rules="[
                                        !!reEnteredPassword
                                            ? isUploadReady
                                            : true,
                                    ]"
                                    hide-details="auto"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="pl-4">
                            <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                                <v-btn
                                    v-on:click="
                                        updatePasswordAction({
                                            password: password,
                                        })
                                    "
                                    :loading="loading"
                                    small
                                    :disabled="
                                        typeof isUploadReady == 'string'
                                            ? true
                                            : !isUploadReady
                                    "
                                    hide-details="auto"
                                    color="primary"
                                    >UPDATE</v-btn
                                >
                            </v-col>
                        </v-row>
                    </div>
                </v-col>
            </v-row>
        </v-card>
    </div>
</template>

<script>
import Breadcrumbs from "@components/Breadcrumbs";
import { mapActions, mapState } from "vuex";
import { rules } from "@assets/js/validator";

export default {
    components: { Breadcrumbs },
    computed: {
        ...mapState("Security", ["loading"]),
        isUploadReady() {
            return rules.pwdMatch(this.password, this.reEnteredPassword);
        },
    },
    watch: {
        loading(nv) {
            if (!nv) {
                this.password = null;
                this.reEnteredPassword = null;
            }
        },
    },
    data() {
        return {
            password: null,
            reEnteredPassword: null,
            rules: rules,
        };
    },
    methods: {
        ...mapActions("Security", ["updatePasswordAction"]),
    },
};
</script>
