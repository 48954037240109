var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home"},[_c('v-card',[_c('Breadcrumbs'),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticStyle:{"padding":"10px"}},[_c('v-row',{staticClass:"pl-4"},[_c('v-col',{staticStyle:{"padding-bottom":"0px"},attrs:{"cols":"12"}},[_c('h3',[_vm._v("Change Password")])]),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"4","lg":"3","xl":"3"}},[_c('v-text-field',{attrs:{"label":"New Password","type":"password","rules":[_vm.rules.min6],"hide-details":"auto"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"4","lg":"3","xl":"3"}},[_c('v-text-field',{attrs:{"label":"Confirm New Password","type":"password","rules":[
                                    !!_vm.reEnteredPassword
                                        ? _vm.isUploadReady
                                        : true ],"hide-details":"auto"},model:{value:(_vm.reEnteredPassword),callback:function ($$v) {_vm.reEnteredPassword=$$v},expression:"reEnteredPassword"}})],1)],1),_c('v-row',{staticClass:"pl-4"},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12","lg":"12","xl":"12"}},[_c('v-btn',{attrs:{"loading":_vm.loading,"small":"","disabled":typeof _vm.isUploadReady == 'string'
                                        ? true
                                        : !_vm.isUploadReady,"hide-details":"auto","color":"primary"},on:{"click":function($event){return _vm.updatePasswordAction({
                                        password: _vm.password,
                                    })}}},[_vm._v("UPDATE")])],1)],1)],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }